import request from '@/common/utils/request.js'
import {
  WebClient
} from '@/api/webClient'
const webClient = new WebClient()
import {
  setReady
} from '@/api/cookies'
import {
  getInfo
} from '@/api/cookies'
import { getDomain } from '@/api/cookies'
export class OrderClient extends WebClient {
  /**  新增订单
   *  kind:大类ID*/
  async addOrders(id, userid, commodityName, commodityType, orderName, kind) {
    const userInfo = getInfo()
    let ids = []
    let type = []
    let tiitle = []
    if ((typeof id) != 'object') {
      tiitle.push(commodityName)
      ids.push(id)
      type.push(commodityType)
    } else {
      ids = id,
      type = commodityType,
      tiitle = commodityName
    }
    return request({
      url: '/stu/order/pcAddOrder',
      method: 'POST',
      data: {
        // 商品Id
        id: ids,
        /* 商品Id  List集合接受 */
        // 用户Id
        userid: userid,
        // 用户手机号
        mobile: userInfo.mobile,
        // 商品名称
        commodityName: tiitle,
        orderName: orderName || commodityName,
        /* 商品名称  list集合接受 */
        commodityType: type,
        orderSource: 'PC端',
        kind: kind
      }
    })
  }
  /** 新增合伙共建订单
   * 订单状态(0待付款/1已付款
   */
  async addOrdersPartner(id, userid, commodityName, commodityType, orderName, kind, payment) {
    const userInfo = getInfo()
    let ids = []
    let type = []
    let tiitle = []
    if ((typeof id) != 'object') {
      tiitle.push(commodityName)
      ids.push(id)
      type.push(commodityType)
    } else {
      ids = id,
      type = commodityType,
      tiitle = commodityName
    }
    return request({
      url: '/stu/order/pcPartnershipAddOrder',
      method: 'POST',
      data: {
        // 商品Id
        id: ids,
        /* 商品Id  List集合接受 */
        // 用户Id
        userid: userid,
        // 用户手机号
        mobile: userInfo.mobile,
        // 商品名称
        commodityName: tiitle,
        orderName: orderName || commodityName,
        /* 商品名称  list集合接受 */
        commodityType: type,
        orderSource: 'PC端',
        tenantid: getDomain().eId,
        payType: payment
      }
    })
  }
  /** 新增M端订单
   * 订单状态(0待付款/1已付款
   */
  async addOrder(commodityType, id, userid, mobile, couponId, commodityName, orderName, marketGroupid, marketHelpid) {
    const data = await request({
      url: `/stu/order/mAddOrder`,
      method: 'POST',
      data: {
        commodityType: commodityType,
        /* 商品类型    list集合形式发送 */
        id: id,
        orderName: orderName,
        /* 订单名称 */
        userid: userid,
        /*  */
        mobile: mobile,
        /* 用户手机号  */
        couponId: couponId,
        /* 优惠卷Id         */
        commodityName: commodityName,
        /* 商品名称   list集合形式发送 */
        orderSource: 'PC端',
        marketGroupid: marketGroupid,
        /* 当前参加拼团活动Id */
        marketHelpid: marketHelpid
        /* 当前参加助力活动Id */
      }
    })
    this.functionNo(data)
    return data
  }
  /* 提交退款申请(学生端订单退款) */
  async mAddOrder(params) {
    const data = await request({
      url: `/stu/studentRefund/addStudentRefund`,
      method: 'POST',
      params
    })
    this.functionNo(data)
    return data
  }
  /*
    查询学生签约信息
    contractId	是	合同ID
    contact	是	签约者联系方式
  */
  async selectQysStudent(params) {
    const data = await request({
      url: `/stu/agency/selectQysStudent`,
      method: 'GET',
      params
    })
    this.functionNo(data)
    return data
  }
  /*
    获取签署页面Url
    contractId	是	合同ID
    contact	是	签约者联系方式
  */
  async contractPageurl(params) {
    const data = await request({
      url: `/stu/agency/contractPageurl`,
      method: 'POST',
      params
    })
    this.functionNo(data)
    return data
  }
  /*
    学生订单名片老师二维码
    userid  学生Id
  */
  async getOrderIsShow(params) {
    const data = await request({
      url: `/stu/order/getOrderIsShow`,
      method: 'GET',
      params
    })
    this.functionNo(data)
    return data
  }
    async addInvoiceManageEntity(params) {
    const data = await request({
      url: `/ucenter/invoiceManage/addInvoiceManageEntity`,
      method: 'POST',
      data:params
    })
    this.functionThen(data)
    return data
  }
    async getInvoiceStatusByOrderNum(params) {
    const data = await request({
      url: `/ucenter/invoiceManage/getInvoiceStatusByOrderNum`,
      method: 'GET',
      params
    })
    this.functionNo(data)
    return data
  }
}
