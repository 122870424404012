var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dataPack"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.goNextRep(`/data/dataPackage`)}}},[_vm._v(" 全部资料 ")]),(_vm.detail && _vm.detail.kindName)?_c('div',[_c('i',{staticClass:"iconfont icon-gengduo"}),_vm._v(_vm._s(_vm.detail ? _vm.detail.kindName : "")+" ")]):_vm._e(),(_vm.detail && _vm.detail.zyName)?_c('div',[_c('i',{staticClass:"iconfont icon-gengduo"}),_vm._v(_vm._s(_vm.detail ? _vm.detail.zyName : "")+" ")]):_vm._e(),(_vm.detail && _vm.detail.kcName)?_c('div',[_c('i',{staticClass:"iconfont icon-gengduo"}),_vm._v(_vm._s(_vm.detail ? _vm.detail.kcName : "")+" ")]):_vm._e()]),(_vm.detail)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"check"},[_c('div',{staticClass:"pageck"},[_c('div',{staticClass:"pageck-title"},[_vm._v(_vm._s(_vm.detail.title))]),_c('div',{staticClass:"tips"},[_c('div',{staticClass:"tips-item"},[(_vm.detail.addTime)?_c('div',{staticClass:"tips-item-chil"},[_c('i',{staticClass:"iconfont icon-a-zu330"}),_vm._v("上传时间："+_vm._s(_vm._f("format")(_vm.detail.addTime,"yyyy/MM/dd"))+" ")]):_vm._e()])])]),(_vm.thisList)?_c('div',{staticClass:"iframe-box"},[(_vm.thisList.fileType == 'word' ||
          _vm.thisList.fileType == 'ppt' ||
          _vm.thisList.fileType == 'excel'
          )?_c('iframe',{attrs:{"seamless":"","src":`https://view.officeapps.live.com/op/view.aspx?src=${_vm.thisList.names}`,"width":"826px","height":"100%","frameborder":"1","noResize":""}}):_c('iframe',{attrs:{"seamless":"","src":_vm.thisList.names,"width":"826px","height":"100%","frameborder":"1","noResize":""}})]):_vm._e()]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"pageck"},[_c('div',{staticClass:"pageck-title"},[_vm._v(_vm._s(_vm.detail.title))]),_c('div',{staticClass:"pageck-price"},[_vm._v(" ￥"+_vm._s(_vm.detail.price ? _vm.detail.price : 0)+" ")])]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item pointer",class:_vm.thisList.mid == item.mid ? 'active' : '',on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.choose(item)}}},[(_vm.thisList.mid == item.mid)?_c('div',{staticClass:"triangle"}):_vm._e(),_c('i',{staticClass:"iconfont icon-a-zu335 item-icon",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.choose(item)}}}),_c('div',{staticClass:"item-title twoEllipsis",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.choose(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('img',{staticClass:"tips_img",attrs:{"src":require("@/assets/img/Home/Group 3999.png"),"alt":""},on:{"click":function($event){return _vm.openKowledge(item)}}})])}),(!_vm.list || _vm.list.length == 0)?_c('div',{staticClass:"nothing"},[_c('div',{staticClass:"nothing-text"},[_vm._v("暂无内容哦~")])]):_vm._e(),(_vm.classesId
          ? !_vm.IsPurchase
          : _vm.detail.isPurchase == 1 && _vm.detail.productPrice > 0
        )?_c('div',{staticClass:"goumai",on:{"click":function($event){return _vm.goOrder()}}},[_vm._v(" 立即购买 ")]):_c('div',{staticClass:"goumai dolow",on:{"click":function($event){return _vm.allDolow(_vm.thisList)}}},[_vm._v(" 下载当前 ")])],2)]):_vm._e(),_c('el-dialog',{attrs:{"visible":_vm.kowledgePointsVisible,"width":"50%","before-close":_vm.kowledgePointsClose,"show-close":false},on:{"update:visible":function($event){_vm.kowledgePointsVisible=$event}}},[_c('div',{staticClass:"kowledgePoints_wrap"},[_c('kowledgePoints',{ref:"kowledgePoints",attrs:{"type":"4"}}),_c('div',{staticClass:"determine_btn",on:{"click":_vm.kowledgePointsClose}},[_vm._v("确定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }