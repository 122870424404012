<template>
  <div class="wrap" :class="{ border_dashed: list.length && type != 3 }">
    <div>
      <el-collapse v-if="list.length" v-model="activeName" accordion @change="changeName">
        <div class="for_box" v-for="(item, index) in list" :key="index">
          <div v-if="index == 0" class="white_block"></div>
          <div :class="{ tag_item: type != 3 }"></div>
          <el-collapse-item :name="index">
            <template slot="title">
              <div class="title_box">
                <div class="name">{{ item.name }}</div>
                <div class="tips">
                  {{ current === index ? '收起' : '展开' }}
                </div>
              </div>
            </template>
            <div>
              <el-table :data="item.bookList">
                <el-table-column
                  label="教材名称"
                  :width="type == 3 ? '340' : type == 4 ? '350' : '450'"
                  align=""
                  prop="bookName"
                ></el-table-column>
                <el-table-column
                  label="教材章"
                  :width="type == 3 ? '185' : type == 4 ? '210' : '310'"
                  align=""
                  prop="chapterName"
                ></el-table-column>
                <el-table-column
                  label="教材节"
                  :width="type == 3 ? '200' : type == 4 ? '230' : '320'"
                  align=""
                  prop="sectionName"
                ></el-table-column> 
                <el-table-column label="页数" :width="type == 3 ? '70' : ''" align="" prop="pageNumber"></el-table-column>
              </el-table>
            </div>
            <div class="grey_box">
              <el-collapse v-model="item.activeName" accordion>
                <el-collapse-item v-if="item.materialsList && item.materialsList.length" title="关联资料">
                  <div class="materials_box">
                    <div
                      class="materials_item"
                      :class="{
                        examination_width: type == 3,
                      }"
                      v-for="materials in item.materialsList"
                      :key="materials.mid"
                    >
                      <div class="img_box">
                        <img
                          v-if="materials.filetype == 'excel'"
                          class="img"
                          src="@/assets/img/study/dataXls.png"
                        />
                        <img
                          v-else-if="materials.filetype == 'word'"
                          class="img"
                          src="@/assets/img/study/dataDoc.png"
                        />
                        <img
                          v-else-if="materials.filetype == 'pdf'"
                          class="img"
                          src="@/assets/img/study/dataPdf.png"
                        />
                        <img
                          v-else-if="materials.filetype == 'ppt'"
                          class="img"
                          src="@/assets/img/study/dataPpt.png"
                        />
                        <img v-else class="img" src="@/assets/img/study/dataFile.png" />
                        <div class="">
                          <div class="title_item">{{ materials.title }}</div>
                          <div class="size">{{ materials.size }}</div>
                        </div>
                      </div>
                      <div class="btn_item" @click="toMaterials(materials)">查看资料</div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="grey_box">
              <el-collapse v-model="item.activeName" accordion>
                <el-collapse-item
                  v-if="(type == 1 || type == 2 || type == 4) && item.subjectList && item.subjectList.length"
                  title="关联试题"
                >
                  <div class="test_questions-box">
                    <div class="test_questions-item" v-for="subject in item.subjectList" :key="subject.id">
                      <div class="name_box">
                        <img class="item_image" src="@/assets/img/study/pager.png" />
                        <div class="title_item twoEllipsis" v-html="subject.questionTitle"></div>
                      </div>
                      <div class="btn_item" @click="toTestQuestion(subject)">查看试题</div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="grey_box">
              <el-collapse v-model="item.activeName" accordion>
                <el-collapse-item
                  v-if="(type == 3 || type == 4) && item.segmentList && item.segmentList.length"
                  title="关联视频"
                >
                  <div class="video-box">
                    <div
                      class="video-item"
                      :class="{
                        examination_width: type == 3,
                      }"
                      v-for="video in item.segmentList"
                      :key="video.id"
                      @click="seeVideo(video)"
                    >
                      <div class="time_box">{{ video.showTime }}</div>
                      <img class="item_img" :src="video.coverUrl" alt="" />
                      <!-- <div class="name_box">{{ video.title }}</div> -->
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-collapse-item>
        </div>
      </el-collapse>
      <div v-if="id && !list.length" class="no-data">暂无数据</div>
    </div>
    <testQuestions
      class="checkExam"
      ref="testQuestions"
      :dialogVisible="dialogVisible"
      @handleClose="handleClose"
    ></testQuestions>
    <kowledgePointsVideo
      ref="kowledgePointsVideo"
      v-if="videoVisible"
      :videoVisible="videoVisible"
      :videoList="videoList"
      @videoClose="videoClose"
    ></kowledgePointsVideo>
  </div>
</template>

<script>
import Vue from 'vue'
import { selectPcKnowledgeDataList, selectDataIsBuyList, selectDocIsBuyList } from '@/api/home'
import testQuestions from './testQuestions'
import kowledgePointsVideo from './kowledgePointsVideo.vue'
export default {
  components: {
    testQuestions,
    kowledgePointsVideo,
  },
  props: [
    'type', // 1直播视频i打点  2录播视频i打点  3试题   4资料
  ],
  data() {
    return {
      list: [],
      activeName: 0,
      current: null,
      dialogVisible: false,
      videoVisible: false,
      videoList: null,
      tableWidth: 0,
      id: null,
    }
  },
  created() {},
  methods: {
    getData(id) {
      this.id = id
      let data = {
        type: this.type,
        typeId: id,
      }
      selectPcKnowledgeDataList(data).then((res) => {
        res.data.forEach((item) => {
          if (item.materialsList) {
            item.materialsList.forEach((m) => {
              let _this = this
              m.filetype = Vue.prototype.getFileType(m.names)
              Vue.prototype.getFilesize(m.names, function (size) {
                let sizeVal = (size / 1028).toFixed(0) + 'k'
                let data = (size / 1028 / 1024).toFixed(0)
                m.size = data > 0 ? data + 'M' : sizeVal
                _this.$forceUpdate()
              })
            })
          }
          if (item?.segmentList?.length) {
            item.segmentList.forEach((segment) => {
              let startTime = segment.startTime.split(':')
              let hour = startTime[0]
              let minute = startTime[1]
              let second = startTime[2]
              segment.playTime = Number(hour * 60 * 60) + Number(minute * 60) + Number(second)
              let endTime = segment.endTime.split(':')
              hour = endTime[0]
              minute = endTime[1]
              second = endTime[2]
              segment.end = Number(hour * 60 * 60) + Number(minute * 60) + Number(second)

              let lengthTime = Number(segment.lengthTime)
              let h = Math.floor(lengthTime / 60 / 60)
              let m = Math.floor((lengthTime - h * 60 * 60) / 60)
              let s = lengthTime - h * 60 * 60 - m * 60
              segment.showTime = `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`
            })
          }
        })

        this.list = res.data
      })
    },
    changeName(e) {
      this.current = e
    },
    async toMaterials(item) {
      let data = {
        docId: item.mid,
      }
      let IsPurchase
      await selectDocIsBuyList(data).then((res) => {
        IsPurchase = res.data
      })
      let routeUrl = this.$router.resolve({
        path: '/VIP/sourcePage',
        query: {
          seeNum: item.seeNum,
          names: item.names,
          title: item.title,
          type: 3,
          IsPurchase: IsPurchase,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
    toTestQuestion(item) {
      this.$refs.testQuestions.init(item)
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    seeVideo(video) {
      let data = {
        courseId: video.courseId,
        type: video.videoType == 'liveCourses' ? 1 : 2,
      }
      let that = this
      selectDataIsBuyList(data).then((res) => {
        if (res.data) {
          that.videoList = video
          that.videoVisible = true
      
          // this.$refs.kowledgePointsVideo.init(video)
        } else {
          this.$message({
            type: 'warning',
            message: '课程未购买',
          })
        }
      })
    },
    videoClose() {
      this.videoVisible = false
      this.$forceUpdate
    },
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  padding-left: 20px;

  .for_box {
    position: relative;

    .tag_item {
      width: 16px;
      height: 16px;
      border: #ff5e51 solid 5px;
      border-radius: 50%;
      background-color: #ffffff;
      position: absolute;
      left: -29px;
      top: 15px;
    }
    .white_block {
      width: 16px;
      height: 16px;
      position: absolute;
      left: -29px;
      top: 0px;
      background-color: #ffffff;
    }
  }

  .title_box {
    display: flex;
    justify-content: space-between;
    width: 98%;

    .name {
      color: #373a42;
      font-weight: bold;
      font-size: 16px;
      width: 700px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tips {
      font-size: 12px;
      color: #777777;
    }
  }
  .grey_box {
    // 资料
    .materials_box {
      padding: 10px;
      display: flex;
      flex-wrap: wrap;

      .examination_width {
        width: 346px !important;
        .img_box {
          .title_item {
            width: 180px !important;
          }
        }
      }
      .materials_item {
        flex-shrink: 0;
        width: 360px;
        height: 72px;
        border-radius: 8px;
        padding: 16px;
        margin-right: 8px;
        margin-bottom: 16px;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.36);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .img_box {
          height: 40px;
          margin-right: 12px;
          display: flex;

          .img {
            width: 40px;
            height: 40px;
            margin-right: 12px;
          }
          .title_item {
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .size {
            color: #999999;
            font-size: 12px;
          }
        }
        .btn_item {
          width: 60px;
          height: 30px;
          border-radius: 4px;
          border: 1px solid #ff5e51;
          box-sizing: border-box;
          color: #ff5e51;
          font-size: 14px;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
    // 试题
    .test_questions-box {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;

      .test_questions-item {
        width: 360px;
        height: 90px;
        border-radius: 8px;
        padding: 16px;
        margin-right: 8px;
        margin-bottom: 16px;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.36);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name_box {
          display: flex;

          .title_item {
            width: 144px;
            height: 46px;
            overflow: hidden;
          }
          .item_image {
            width: 42px;
            height: 58px;
            margin-right: 12px;
          }
        }
        .btn_item {
          width: 80px;
          height: 30px;
          border-radius: 4px;
          background-color: #ff5e51;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
    // 视频
    .video-box {
      display: flex;
      flex-wrap: wrap;

      .examination_width {
        width: 167px !important;

        .item_img {
          width: 167px !important;
        }
      }
      .video-item {
        width: 189px;
        height: 100px;
        margin-right: 15px;
        margin-bottom: 16px;
        position: relative;
        cursor: pointer;

        .time_box {
          width: 53px;
          height: 20px;
          border-radius: 12px;
          background-color: rgba(0, 0, 0, 0.8);
          color: #ffffff;
          position: absolute;
          top: 6px;
          right: 8px;
          font-size: 12px;
          text-align: center;
        }
        .item_img {
          border-radius: 8px;
          width: 189px;
          height: 100px;
        }
      }
    }
  }
}

.border_dashed {
  border-left: #ff5e51 1px dashed;
}

::v-deep .el-table__header-wrapper {
  height: 60px;
  border-radius: 8px 8px 0px 0px;
  th {
    background-color: #fff3f2;
    border: none;
    color: #333333;
  }
}

.no-data {
  height: 400px;
  text-align: center;
  line-height: 400px;
}

.grey_box ::v-deep .el-collapse-item__header {
  background-color: #ffffff;
  border: none;
  color: #303133;
}
.grey_box ::v-deep .el-collapse-item__wrap {
  background-color: #ffffff;
  border: none;
}
.grey_box ::v-deep .el-collapse-item__arrow {
  margin: 0;
  margin-left: 6px;
}

::v-deep .el-table__header-wrapper .el-table__header thead {
  box-shadow: 0px 4px 8px 1px rgba(245, 95, 83, 0.16);
}
::v-deep .el-table__body-wrapper {
  color: #333333;
  font-size: 14px;
}
::v-deep .el-collapse {
  border: none;
}
.el-table {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400 !important;
  color: #333333;
}
.el-table .cell {
  font-weight: 400;
  font-size: 14px;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0 !important;
}
</style>
