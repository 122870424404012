<template>
  <div>
    <el-dialog :visible.sync="videoVisible" width="720px" :before-close="videoClose" :modal="false">
      <div class="popup_box" :class="!isMp4 ? 'isMp4' : ''">
        <div v-if="currentVideo.ccRomid">
          <div id="playbackPlayer" />
          <div id="playbackPanel" />
        </div>
        <jxyplayer v-else-if="!isMp4 && xgurl && accessKeyId && accessKeySecret && videoVisible
          " :access-key-id="accessKeyId" :url="xgurl" :logo-url="copyright.videoLogo"
          :access-key-secret="accessKeySecret" :options="option" @player="aliplayer" />
        <div v-else id="J_prismPlayer">
          <div id="logo">
            <img :src="copyright.videoLogo" style="object-fit: contain">
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { playAuth } from '@/api/know'
import { getDomain } from '@/api/cookies'
import jxyplayer from '@/components/jxyPlay.vue'
import { getAccessKey } from '@/api/home'
export default {
  components: { jxyplayer },
  props: ['videoVisible', 'videoList'],
  data() {
    return {
      timer: null,
      player: null,
      currentVideo: {},
      firstPlayFlag: true,
      isMp4: true,
      copyright: {},
      xgurl: '',
      accessKeySecret: null,
      accessKeyId: null,
      option: {
        width: '100%',
        height: '98%',
        type: 'HLS'
      }
    }
  },
  async created() {

    this.copyright = await getDomain()

  },
  mounted() {
    this.currentVideo = this.videoList
    this.getAccessKey()

    if (this.videoList.ccRomid) {
      // CC直播
      this.CClive()
    } else {
      // 阿里直播回放 vid
      setTimeout(() => {
        this.aliplayer()
      }, 1000)
    }
  },
  methods: {
    videoClose() {
      if (this.player) {
        clearTimeout(this.timer)
        this.player.dispose()
      } else {
        clearInterval(this.timer)
        $.DW.destroy()
      }
      (this.timer = null),
        (this.player = null),
        (this.currentVideo = {}),
        (this.firstPlayFlag = true),
        this.$emit('videoClose')
    },

    async getAccessKey() {
      const res = await getAccessKey()
      if (res.code == 0) {
        this.accessKey = res.data
        this.accessKeyId = res.data.accessKeyId
        this.accessKeySecret = res.data.accessKeySecret
      }
    },
    // 阿里初始化
    async aliplayer(xgplayer) {
      const _this = this
      if (
        this.currentVideo.videoType == 'videoCourses' &&
        _this.currentVideo.videoConfig.endsWith('mp4')
      ) {
        // 阿里录播
        this.player = new Aliplayer(
          {
            id: 'J_prismPlayer',
            source: _this.currentVideo.videoConfig
          },
          function (player) {
            player.seek(_this.currentVideo.playTime)
          }
        )
      } else if (_this.currentVideo.videoConfig.endsWith('m3u8')) {
        this.isMp4 = false

        _this.xgurl = _this.currentVideo.videoConfig
        xgplayer.currentTime = _this.currentVideo.playTime
        xgplayer.on('timeupdate', function () {

          if (xgplayer.currentTime >= _this.currentVideo.end) {
            xgplayer.pause()
            xgplayer.currentTime = _this.currentVideo.playTime
          }
        })
        // xgplayer.url = _this.currentVideo.videoConfig;
      } else {
        let playauth = ''
        await playAuth(this.currentVideo.videoConfig)
          .then((res) => {

            playauth = res.data
          })
          .then(() => {
            // 阿里直播回放
            this.player = new Aliplayer(
              {
                id: 'J_prismPlayer',
                vid: this.currentVideo.videoConfig,
                playauth
              },
              function (player) {
                player.seek(_this.currentVideo.playTime)
              }
            )
          })
      }

      this.$nextTick(() => {
        if (_this.currentVideo.videoConfig.endsWith('mp4')) {
          this.player.on('timeupdate', () => {
            this.timeupdate()
          })
        }
      })
    },
    // 限制播放时间段
    timeupdate() {
      const current = this.player.getCurrentTime()
      if (this.timer) return

      this.timer = setTimeout(() => {
        if (current > this.currentVideo.end) {
          this.player.pause()
          this.player.seek(this.currentVideo.playTime)
        } else if (current < this.currentVideo.playTime) {
          this.player.seek(this.currentVideo.playTime)
        }
        clearTimeout(this.timer)
        this.timer = null
      }, 1000)
    },

    // CC直播初始化
    async CClive() {
      const that = this
      $.DW.config({
        userId: '41A331E332E32281',
        roomId: this.currentVideo.ccRomid,
        recordId: this.currentVideo.recordId
      })
      window.on_cc_live_player_load = () => {
        if (that.firstPlayFlag) {
          this.CCTimeUpdate()
          that.firstPlayFlag = false
        }
        const playbackVideo = document.querySelector('#playbackVideo')
        playbackVideo.setAttribute('controlsList', 'noplaybackrate ')
      }
    },

    CCTimeUpdate() {
      $.DW.seek(this.currentVideo.playTime)
      this.timer = setInterval(() => {
        if ($.DW.getPlayerTime() > this.currentVideo.end) {
          $.DW.play()
          $.DW.seek(this.currentVideo.playTime)
        } else if ($.DW.getPlayerTime() < this.currentVideo.playTime) {
          $.DW.seek(this.currentVideo.playTime)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.popup_box {
  // height: 600px;
  padding-top: 20px;
  padding-bottom: 30px;

  #playbackPlayer {
    width: 50%;
    margin: auto;
  }

  #playbackPanel {
    height: 300px;
  }
}

.isMp4 {
  height: 450px;
}

#logo {
  position: absolute;
  z-index: 999;
  right: 30px;
  bottom: 20px;

  img {
    // width: 400px;
    height: 40px;
  }
}
</style>
