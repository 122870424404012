<template>
  <!-- 知识点查看试题页面 -->

  <div>
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose" :modal="false" class="checkExam">
      <queCar :detail="subList[0]" :isSubmited="true" :IsPurchase="true" :isZsd="isZsd" :isTry="false"
        source="kowledgePoints"></queCar>
    </el-dialog>
  </div>
</template>

<script>
import queCar from '@/views/quesTion/queCar.vue'
export default {
  props: ['dialogVisible'],
  components: {
    queCar
  },
  data() {
    return {
      subList: [],
      isZsd: false
    }
  },
  methods: {
    init(item) {
      let i = JSON.parse(JSON.stringify(item))
      this.Splicing([i], false)

    },
    /* 试题拼接 */
    Splicing(list, ans) {
      this.TiXing = [0, 0, 0, 0, 0, 0]

      /* ans  是否是提交显示的 */
      let shiti = []
      for (let item of list) {
        item.questionType = Number(item.questionType)
        if (
          item.questionType == 0 ||
          item.questionType == 1 ||
          item.questionType == 6
        ) {
          let data = JSON.parse(item.questionOption)
          let sub = []
          for (let i in data) {
            let val = {
              val: data[i],
              num: String.fromCharCode(Number(i) + 65)
            }
            sub.push(val)
          }
          item.optionList = sub
          if (ans && (item.questionType == 1 || item.questionType == 6)) {
            item.questionUserAnswer = []
          }
          if (!ans && (item.questionType == 1 || item.questionType == 6)) {
            if (item.questionUserAnswer) {
              item.questionUserAnswer = item.questionUserAnswer.split(',')
            } else {
              item.questionUserAnswer = []
            }
          }
          shiti.push(item)
        } else if (item.questionType == 2) {
          item.optionList = [
            { val: '对', num: 'A' },
            { val: '错', num: 'B' }
          ]
          shiti.push(item)
        } else if (item.questionType == 5) {
          if (item.childSubject) {
            for (let chil of item.childSubject) {
              chil.questionType = Number(chil.questionType)
              if (
                chil.questionType == 0 ||
                chil.questionType == 1 ||
                chil.questionType == 6
              ) {
                let data = JSON.parse(chil.questionOption)
                let sub = []
                for (let i in data) {
                  let val = {
                    val: data[i],
                    num: String.fromCharCode(Number(i) + 65)
                  }
                  sub.push(val)
                }
                chil.optionList = sub
                if (ans && (chil.questionType == 1 || chil.questionType == 6)) {
                  chil.questionUserAnswer = []
                }
                if (
                  !ans &&
                  (chil.questionType == 1 || chil.questionType == 6)
                ) {
                  if (chil.questionUserAnswer) {
                    chil.questionUserAnswer = chil.questionUserAnswer.split(',')
                  } else {
                    chil.questionUserAnswer = []
                  }
                }
              } else if (chil.questionType == 2) {
                chil.optionList = [
                  { val: '对', num: 'A' },
                  { val: '错', num: 'B' }
                ]
              }
            }
            shiti = shiti.concat(item.childSubject)
          }
        } else {
          shiti.push(item)
        }
      }
      this.subjectList = shiti
      this.subList = list //提交时用  同数据源数据会一起变化
      //添加 字段
      for (let i in this.subjectList) {
        let item = this.subjectList[i]
        item.indexVal = Number(i) + 1
        this.TiXing[item.questionType]++
      }
    },

    handleClose() {
      this.subList = []
      this.$emit('handleClose')
    }
  }
}
</script>

<style lang="less" scoped>
.checkExam {
  ::v-deep .el-dialog {
    width: 786px !important;
    max-height: 770px !important;
    overflow: hidden;
    overflow-y: auto;
  }
}

::v-deep .el-dialog {
  border-radius: 10px;
}
</style>
