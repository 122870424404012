<template>
  <div :style="`width:${opt.width};height:${opt.height};position:relative`" class="test">
    <!-- 主播放器 -->
    <div id="jxyPlayer" :style="`position:absolute;left: 0; top: 0;height: ${opt.height};width: ${opt.width}`">
      <img v-if="opt.logoUrl && !opt.leftLogo"
        :style="`position: absolute; right: 20px;bottom: 20px; width: ${opt.logoWidth}px;height:${opt.logoHeight}`"
        :src="opt.logoUrl">
      <img v-if="openType == 2" :style="`position: absolute; left: 20px;bottom: 20px; width: auto;height:${opt.logoHeight}`
        " src="https://staticfile.xlcwx.com/m-images/yth/lzimg/jx/xlcbg.png" />

      <!-- 广告区域 -->
      <!-- <div
        v-show="opt.aDType == 'video' && paused"
        class="AD"
        :style="`position: absolute; width: ${opt.aDWidth}; height: ${opt.aDHeight}; z-index: 998; left: 50%; top: 50%; transform:translate(-50%,-50%)`"
      >
        <div id="jxyPlayerAD">
          <a
            :href="opt.aDsrc"
            target="_Blank"
            :style="`position: absolute; left: 0; top: 0; display: block;width: ${'100%'} ;height: ${'100%'};z-index:999`"
          />
        </div>
      </div> -->
      <!-- 轮播图广告 -->
      <!-- <div
        v-if="opt.aDType == 'swiper' && paused"
        class="swiperAD"
        :style="`position: absolute; width: ${opt.aDWidth}; height: ${opt.aDHeight}; z-index: 999; left: 50%; top: 50%; transform:translate(-50%,-50%);`"
      >
        <a
          :href="opt.aDsrc"
          target="_Blank"
          :style="`position: absolute; left: 0; top: 0; display: block;width: ${'100%'} ;height: ${'100%'};z-index:999`"
        />
        <swiper
          :autoplay="{
            delay: 1500,
            disableOnInteraction: false,
          }"
          :modules="modules"
          class="mySwiper"
        >
          <swiper-slide
            v-for="(item, index) in opt.aDSwiper"
            :key="index"
          ><img
            :src="item.url"
            alt=""
          ></swiper-slide>
        </swiper>
      </div> -->
    </div>
    <!-- 贴片广告 -->
    <!-- <div
      v-if="videoAdOverlay"
      id="jxyPlayerOP"
      :style="`position:absolute;left: 0; top: 0;height: ${opt.height};width: ${opt.width};z-index:${PlayerOPended}`"
    >
      <a
        :href="opt.playeraDurlOPTarget"
        target="_Blank"
        :style="`position: absolute; left: 0; right: 0; display: inline-block;width: ${opt.widht} ;height: ${opt.height};`"
      />
      <div
        class="SkipBtn"
        :class="{ disabled: isDisabled }"
        style="
          cursor: pointer;
          width: 140px;
          height: 40px;
          font-size: 20px;
          color: rgba(255, 255, 255, 0.6);
          text-align: center;
          line-height: 40px;
          position: absolute;
          right: 20px;
          top: 20px;
          background-color: rgba(0, 0, 0, 0.6);
        "
        @click="closeAD"
      >
        <span v-if="skipTime > 0" style="font-size: 16px; margin-right: 10px">{{
          `${ADTime}S`
        }}</span>
        <span>后跳过广告</span>
      </div>
    </div>
    <div
      v-if="videoAdOverlay"
      id="jxyPlayerEP"
      :style="`position:absolute;left: 0; top: 0;height: ${opt.height};width: ${opt.width};z-index:${PlayerEPended}`"
    >
      <a
        :href="opt.playeraDurlEPTarget"
        target="_Blank"
        :style="`position: absolute; left: 0; right: 0; display: inline-block;width: ${opt.widht} ;height: ${opt.height};`"
      />
    </div> -->
    <div>
      <!-- <button style="position: absolute; top: 600px" @click="test">test</button> -->
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "swiper";
import { Autoplay } from "swiper";
import "swiper/swiper.min.css";
import Player from "xgplayer";
import { getAccessKey } from "@/api/home";
import HlsJsPlayer from "xgplayer-hls.js";
export default {
  name: 'JxyPlayer',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    options: {
      type: Object,
      default: () => { }
    },

    openType: {
      type: String,
      default: '1'
    },
    aDType: {
      type: String,
      default: 'none'
    },
    aDSwiper: {
      type: Array,
      default: () => [
        {
          url: 'https://img2.baidu.com/it/u=1688153644,1490540018&fm=253&fmt=auto&app=120&f=JPEG?w=650&h=495'
        },
        {
          url: 'https://img1.baidu.com/it/u=1323422903,4049363711&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=889'
        },
        {
          url: 'https://img1.baidu.com/it/u=2676533820,1803958359&fm=253&fmt=auto&app=138&f=JPEG?w=600&h=400'
        }
      ]
    },
    aDsrc: {
      type: String,
      default: 'https://www.baidu.com'
    },
    aDWidth: {
      type: [String],
      default: '50%'
    },
    aDHeight: {
      type: [String],
      default: '50%'
    },
    leftLogo: {
      type: Boolean,
      defalut: false
    },
    logoUrl: {
      type: String,
      default: ''
    },
    logoWidth: {
      type: [String, Number],
      default: 105
    },
    logoHeight: {
      type: [String, Number],
      default: 50
    },
    aDurl: {
      type: String,
      default:
        '//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4'
    },
    url: {
      type: String,
      default:
        '//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4'
    },
    width: {
      type: [String],
      default: '600px'
    },
    height: {
      type: [String],
      default: '337px'
    },
    fluid: {
      type: Boolean,
      default: false
    },
    poster: {
      type: String,
      default: ''
    },
    // 清晰度
    definition: {
      type: Array,
      default: () => []
    },
    // 进度条标识
    progressDot: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'MP4'
    },
    volume: {
      type: [String, Number],
      default: 0.6
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    autoplayMuted: {
      type: Boolean,
      default: false
    },
    pip: {
      type: Boolean,
      default: false
    },
    screenShot: {
      type: Boolean,
      default: false
    },
    // 前后广告
    videoAdOverlay: {
      type: Boolean,
      default: true
    },
    // 跳过时间
    skipTime: {
      type: Number,
      default: 3
    },
    // 广告链接
    playeraDurlOP: {
      type: String,
      default: ''
    },
    playeraDurlOPTarget: {
      type: String,
      default: ''
    },
    playeraDurlEP: {
      type: String,
      default: ''
    },
    accessKeyId: {
      type: String,
      default: ''
    },
    accessKeySecret: {
      type: String,
      default: ''
    },
    playeraDurlEPTarget: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      paused: false,
      player1: null,
      player2: null,
      modules: [Autoplay],
      opt: {},
      playerOP: null,
      PlayerOPended: -1,
      playerEP: null,
      PlayerEPended: -1,
      ADTime: 0,
      isDisabled: true
    }
  },
  async mounted() {
    this.initOpt()
    this.player1 = await this.initVideo()
    if (this.opt.leftLogo) {
      // 选中 jxyPlayer 元素
      const jxyPlayer = document.getElementById('jxyPlayer')

      // 获取 jxyPlayer 元素下所有的直接子元素
      const children = jxyPlayer.children

      // 选中具有 'xgplayer-placeholder' 类名的第一个直接子元素（如果有的话）
      let placeholder
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        if (child.classList.contains('xgplayer-controls')) {
          placeholder = child.children[0]
          break
        }
      }
      const img = document.createElement('img')

      // 设置 img 标签的样式和 src 属性
      // img.style.width = '100%'; // 根据需要设置样式
      img.style.height = this.opt.logoHeight
      img.style.width = this.opt.logoWidth
      img.src = opt.logoUrl // 根据需要设置图片路径

      placeholder.appendChild(img)

    }
    // 轮播图
    this.$emit('player', this.player1)
    this.initSwiper()
    this.ADTime = this.skipTime
    // this.playerOP.play()
  },
  methods: {
    closeAD() {
      this.playerOP.pause()
      if (!this.isDisabled) {
        this.playerOP.destroy()
      }
    },
    initOpt() {
      this.opt = {
        aDType: this.aDType,
        aDSwiper: this.aDSwiper,
        aDsrc: this.aDsrc,
        aDWidth: this.aDWidth,
        aDHeight: this.aDHeight,
        logoUrl: this.logoUrl,
        logoWidth: this.logoWidth,
        aDurl: this.aDurl,
        url: this.url,
        width: this.width,
        height: this.height,
        fluid: this.fluid,
        poster: this.poster,
        definition: this.definition,
        progressDot: this.progressDot,
        accessKeyId: this.accessKeyId,
        accessKeySecret: this.accessKeySecret,
        type: this.type,
        volume: this.volume,
        autoplay: this.autoplay,
        autoplayMuted: this.autoplayMuted,
        pip: this.pip,
        screenShot: this.screenShot,
        videoAdOverlay: this.videoAdOverlay,
        skipTime: this.skipTime,
        playeraDurlOP: this.playeraDurlOP,
        playeraDurlOPTarget: this.playeraDurlOPTarget,
        playeraDurlEP: this.playeraDurlEP,
        playeraDurlEPTarget: this.playeraDurlEPTarget,
        leftLogo: this.leftLogo,
        logoHeight: this.logoHeight
      }
      if (Object.keys(this.options).length != 0) {
        for (const key in this.options) {
          if (this.options.hasOwnProperty(key)) {
            this.opt[key] = this.options[key]
          }
        }
      }
    },
    test() {

      this.playerEP.pause()
    },
    initOptions() {
      this.opt
    },
    initSwiper() { },

    initVideo() {
      const that = this
      // this.playerOP = this.initVideoOP()
      // this.player2 = this.initVideoAD()
      // this.playerEP = this.initVideoEP()
      // this.playerEP.pause()
      let Temp = Player
      if (this.opt.type == 'MP4') {
        Temp = Player
      } else if (this.opt.type == 'HLS') {
        Temp = HlsJsPlayer
      }

      if (this.url && this.accessKeySecret && this.accessKeyId) {
        const that = this

        const player = new Temp({
          id: 'jxyPlayer', // id
          url: this.url, // this.url, // url
          playsinline: true, // 内联模式
          videoInit: true, // 显示首帧
          lang: 'zh-cn',
          autoplay: true, // 自动播放
          screenShot: this.opt.screenShot, // 截图
          preloADTime: 30,
          minCachedTime: 5,
          cors: true, // 不知道是啥
          width: this.opt.width < 400 ? 400 : this.opt.width, // 宽
          height: this.opt.height < 100 ? 100 : this.opt.height, // 高
          volume: this.opt.volume, // 默认音量
          fluid: this.opt.fluid, // 流式布局
          poster: this.opt.poster, // 默认封面
          playbackRate: [1.0, 1.5], // 倍速
          progressDot: this.opt.progressDot, // 进度条特殊标识
          pip: this.opt.pip, // 画中画(小窗播放)
          hlsOpts: {
            xhrSetup: function (xhr, url) {
              xhr.setRequestHeader('accessKey', that.accessKeyId)
              xhr.setRequestHeader('accessSecret', that.accessKeySecret)
            }
          },
          autoplayMuted: this.autoplayMuted, // 静音add /
          el: document.querySelector('#jxyPlayer')
          // 'x5-video-player-fullscreen': false
        })
        // 清晰度转换
        player.emit('resourceReADy', this.opt.definition)
        // 用于控制广告开关
        player.on('pause', function () {
          that.paused = true
        })
        player.on('play', function () {
          // that.playerEP.pause()
        })
        player.on('playing', function () {
          that.paused = false
          // that.playerEP.pause()
        })
        player.once('ended', function () {
          that.PlayerEPended = 999
          // player.exitFullscreen(player.root);
          // that.playerEP.play()
        })

        return player
      }
    },
    // initVideoAD() {
    //   const player = new Player({
    //     id: "jxyPlayerAD", // id
    //     url: this.opt.aDurl, // url
    //     playsinline: true, // 内联模式
    //     lang: "zh-cn",
    //     autoplay: true, // 自动播放
    //     preloADTime: 30,
    //     minCachedTime: 5,
    //     cors: true, // 不知道是啥
    //     width: "100%", // 宽
    //     height: "100%", // 高
    //     controls: false,
    //     closeVideoClick: true,
    //     closeVideoDblclick: true,
    //     autoplayMuted: true,
    //     keyShortcut: "false", // 快捷键
    //     loop: true,
    //     // 'x5-video-player-fullscreen': false
    //   });
    //   return player;
    // },
    // initVideoOP() {
    //   const that = this;
    //   const player = new HlsJsPlayer({
    //     id: "jxyPlayerOP", // id
    //     url: this.opt.playeraDurlOP, // url
    //     playsinline: false, // 内联模式
    //     lang: "zh-cn",
    //     autoplay: true, // 自动播放
    //     preloADTime: 30,
    //     minCachedTime: 5,
    //     cors: true, // 不知道是啥
    //     width: "100%", // 宽
    //     height: "100%", // 高
    //     controls: false,
    //     closeVideoClick: true,
    //     closeVideoDblclick: true,
    //     autoplayMuted: true,
    //     keyShortcut: "false", // 快捷键
    //     loop: false,
    //     // 'x5-video-player-fullscreen': false
    //   });
    //   player.once("ready", () => {
    //     that.PlayerOPended = 999;
    //   });
    //   player.on("ended", function () {
    //     player.destroy(true);
    //   });
    //   player.on("ready", function () {
    //     // 每一秒减少一秒，并更新倒计时的显示
    //     const intervalId = setInterval(() => {
    //       that.playerOP.play();
    //       that.ADTime--;
    //       // 如果倒计时结束，则清除 interval 并停止倒计时
    //       if (that.ADTime <= 0) {
    //         clearInterval(intervalId);
    //         that.isDisabled = false;
    //       }
    //     }, 1000);
    //   });
    //   player.on("destroy", function () {
    //     that.PlayerOPended = -1;
    //     that.playerOP.pause();
    //     that.playerEP.pause();
    //     if (that.opt.autoplay) {
    //       that.player1.play();
    //     }
    //   });
    //   return player;
    // },
    // initVideoEP() {
    //   const that = this;
    //   const player = new HlsJsPlayer({
    //     id: "jxyPlayerEP", // id
    //     url: this.opt.playeraDurlEP, // url
    //     playsinline: false, // 内联模式
    //     lang: "zh-cn",
    //     autoplay: true, // 自动播放
    //     preloADTime: 30,
    //     minCachedTime: 5,
    //     cors: true, // 不知道是啥
    //     width: "100%", // 宽
    //     height: "100%", // 高
    //     controls: false,
    //     closeVideoClick: true,
    //     closeVideoDblclick: true,
    //     autoplayMuted: false,
    //     keyShortcut: "false", // 快捷键
    //     loop: false,
    //     // 'x5-video-player-fullscreen': false
    //   });
    //   player.once("ready", () => {
    //     that.PlayerEPended = -1;
    //   });
    //   player.on("ended", function () {
    //     player.destroy(true);
    //   });
    //   player.on("destroy", function () {
    //     that.PlayerEPended = -1;
    //   });
    //   return player;
    // },

    // 清晰度转换函数
    resourceReADy() { }
  }
}
</script>
<style scoped>
:deep(.disabled) {
  opacity: 0.5;
  cursor: not-allowed !important;
}

:deep(.AD .xgplayer-start) {
  display: none !important;
  opacity: 0 !important;
}

.swiperAD {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

:deep(.swiper) {
  width: 100% !important;
  height: 100% !important;
}

:deep(.swiper-slide) {
  text-align: center !important;
  font-size: 18px !important;
  background: #fff !important;

  /* Center slide text vertically */
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  -webkit-align-items: center !important;
  align-items: center !important;
}

:deep(.swiper-slide) img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
</style>
